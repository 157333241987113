import { makeStyles, createStyles } from '@material-ui/core/styles';

const textFontSize = 14;

export default makeStyles(() => createStyles(
  {
    content: {
      width: '100%',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 50,
      backgroundColor: '#fff',
    },
    itemText: {
      flex: 0.25,
      textAlign: 'start',
      fontSize: textFontSize,
    },
    legendText: {
      flex: 0.25,
      textAlign: 'end',
      fontSize: textFontSize,
    },
    paginationContent: {
      flex: 0.5,
      '& .pagination': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .pagination > li': {
        listStyle: 'none',
        margin: '0 3px',
      },
      '& .pagination > li > a, .pagination > li > span': {
        fontSize: textFontSize,
        padding: '0 10px',
        margin: '0, 2px',
      },
      '& .pagination > li.active > a': {
        padding: '3px 9px',
        color: '#fff',
        backgroundColor: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        borderRadius: 5,
      },
      '& .pagination > li.active > a:hover': {
        cursor: 'default',
      },
      '& .pagination > li > a:hover': {
        cursor: 'pointer',
        padding: '3px 9px',
        color: '#fff',
        backgroundColor: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        borderRadius: 5,
      },
      '& .pagination > li.previous > a:hover, .pagination > li.next > a:hover': {
        color: '#fff',
        padding: '4px 10px',
        backgroundColor: '#000',
        border: 'none',
      },
      '& .pagination > li.previous.disable > a, .pagination > li.next.disable > a': {
        cursor: 'default',
        color: 'gainsboro',
        background: 'none',
      },
      '& .pagination > li.previous.disable > a:hover, .pagination > li.next.disable > a:hover': {
        cursor: 'default',
        color: 'gainsboro',
      },
    },
  },
));
