import { useQuery } from 'react-query';

import { apiUserRemote } from '../../api/services';

export const useApiClientMapping = (): { apiClientMapping: { [key: string]: string; }; } => {
  const {
    data: apiClientMapping,
  } = useQuery('apiClientMapping', apiUserRemote.getApiClientList, {
    cacheTime: 0,
    initialData: null,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return {
    apiClientMapping,
  };
};
