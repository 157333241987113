import { useState } from 'react';

import { Markup } from '../../../../models';

const ZERO_OR_POSITIVE_DECIMAL_NUMBER = /^[+]?\d+(\.\d+)?$/;

export const useValidation = (mutated: Markup, onSubmit: (event) => void): {
  errorMessage: string;
  onValidate: (event) => void;
} => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onValidate = (event): void => {
    event.preventDefault();
    setErrorMessage('');

    if (ZERO_OR_POSITIVE_DECIMAL_NUMBER.test(mutated.markup_value.toString())) {
      onSubmit(event);
      return;
    }

    setErrorMessage('dialogs.providerMarkup.invalidMarkupValue');
  };

  return {
    errorMessage,
    onValidate,
  };
};
