import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { VisibilitySharp as VisibilityOnIcon, VisibilityOffSharp as VisibilityOffIcon } from '@material-ui/icons';
import {
  Box, FormControl, FormControlLabel, IconButton, InputLabel, Select, Switch, TextField,
} from '../../../common';

import CommonDialogButtons from '../../../common/commonDialogButtons/CommonDialogButtons';
import CommonDialogError from '../../../common/commonDialogError/CommonDialogError';
import CommonTableDialog from '../../../common/commonTableDialog/CommonTableDialog';

import { PortalUser, UserRole } from '../../../../models';
import UserRoleType from '../../../../models/UserRoleType.enum';

import { useCommonInputLogic } from '../../../../hooks/useCommonInputLogic';
import { usePasswordToggle } from '../../../../hooks/usePasswordToggle';

import currencyMock from '../../../../utils/currencyMock';
import measurementSystemDesc from '../../../../utils/measurementSystem';
import useStyles from '../../../commonDialogInputStyles/commonDialogInputStyles';

const CURRENCY_LIST_MOCK = currencyMock;
const MEASUREMENT_SYSTEM_LIST_MOCK = Array.from(Object.entries(measurementSystemDesc));

interface AddEditPortalUserDialogProps {
  errors: Array<string> | null;
  isMutating: boolean;
  isOpen: boolean;
  userData: PortalUser;
  onClose: () => void;
  onSuccess: (apiUser: PortalUser) => void;
}

const AddEditPortalUserDialog: FC<AddEditPortalUserDialogProps> = (props: AddEditPortalUserDialogProps) => {
  const {
    errors, isMutating, isOpen, userData, onClose, onSuccess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutated, onChange, onSubmit } = useCommonInputLogic<PortalUser>(userData, onSuccess);
  const {
    isPasswordType, passwordInputRef, toggleInputType, onToggleVisibility,
  } = usePasswordToggle();

  return (
    <CommonTableDialog dialogTitle={`dialogs.portalUser.${!mutated.id ? 'createTitle' : 'updateTitle'}`} isDialogOpen={isOpen} onClose={onClose}>
      <Box mb="16px">
        { errors && <CommonDialogError errors={errors} /> }
      </Box>
      <form onSubmit={onSubmit}>
        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="userName"
              onChange={onChange}
              label={t('table.columns.portalUser.userName')}
              value={mutated.userName}
            />
          </Box>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield} disabled>
              <InputLabel htmlFor="roles">{t('table.columns.portalUser.roles')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'roles',
                  name: 'roles',
                }}
                onChange={onChange}
                label={t('table.columns.portalUser.roles')}
                value={(mutated.roles as Array<UserRole>)[0].name} // BE is still inconsistent about this
              >
                <option aria-label="None" value={UserRoleType.ADMIN}>{'Admin'}</option>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <Box display="flex" alignItems="center" width="100%">
              <TextField
                inputRef={passwordInputRef}
                required={typeof userData.id === 'undefined'}
                fullWidth
                variant="outlined"
                margin="dense"
                autoComplete="off"
                type={toggleInputType}
                classes={{ root: classes.textfield }}
                name="password"
                onChange={onChange}
                label={t('table.columns.portalUser.password')}
                value={mutated.password}
              />
              <IconButton
                aria-label="toggleVisibility"
                data-item="toggleVisibility"
                color="default"
                className={isPasswordType ? classes.visibilityIconButton : classes.visibilityIconButtonClicked}
                onClick={onToggleVisibility}
              >
                { isPasswordType ? (
                  <VisibilityOffIcon className={classes.visibilityIcon} />
                ) : (
                  <VisibilityOnIcon className={classes.visibilityIcon} />
                ) }
              </IconButton>
            </Box>
            { userData.id && <Box className={classes.passwordHelperText}>{t('dialogs.portalUser.passwordHint')}</Box> }
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              inputProps={{ type: 'email' }}
              name="email"
              onChange={onChange}
              label={t('table.columns.portalUser.email')}
              value={mutated.email}
            />
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="otaName"
              onChange={onChange}
              label={t('table.columns.portalUser.otaName')}
              value={mutated.otaName}
            />
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              disabled
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              classes={{ root: classes.textfield }}
              name=""
              onChange={onChange}
              label={t('table.columns.portalUser.expireDate')}
              value={''}
            />
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <FormControl fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="measurement-system">{t('table.columns.portalUser.measurementSystem')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'measurement-system',
                  name: 'unit',
                }}
                onChange={onChange}
                label={t('table.columns.portalUser.measurementSystem')}
                value={(mutated.unit as { id: number; name: string; })?.name || mutated.unit} // BE is still inconsistent about this
              >
                <option aria-label="None" value="" />
                { MEASUREMENT_SYSTEM_LIST_MOCK.map(
                  (el) => <option key={el[1]} value={el[0]}>{t(`measurementSystemText.${el[1]}`)}</option>,
                ) }
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.rowItem}>
            <FormControl fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="currency-code">{t('table.columns.portalUser.currency')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'currency-code',
                  name: 'currency',
                }}
                onChange={onChange}
                label={t('table.columns.portalUser.currency')}
                value={(mutated.currency as { id: number; name: string; })?.name || mutated.currency} // BE is still inconsistent about this
              >
                <option aria-label="None" value="" />
                { CURRENCY_LIST_MOCK.map(
                  (el) => <option key={el.id} value={el.name}>{el.name}</option>,
                ) }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <Box mt="10px" display="flex">
              <FormControlLabel
                control={<Switch name="active" color="primary" checked={mutated.active} onChange={onChange} />}
                label={t('table.columns.portalUser.active')}
              />
            </Box>
          </Box>
        </Box>

        <CommonDialogButtons
          actionButtonTitle={!mutated.id ? 'createBtnTitle' : 'updateBtnTitle'}
          isMutating={isMutating}
          onClose={onClose}
        />

      </form>
    </CommonTableDialog>
  );
};

export default AddEditPortalUserDialog;
