import React, { FC } from 'react';
import ShortUniqueId from 'short-unique-id';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '..';

const useStyles = makeStyles((theme: Theme) => createStyles({
  errorContent: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderLeft: '4px solid #000',
    borderRadius: 8,
    width: '100%',
    height: 35,
    marginBottom: 4,
  },
  error: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
}));

const uid: ShortUniqueId = new ShortUniqueId();

export interface CommonDialogErrorProps {
  errors: Array<string>;
}

const CommonDialogError: FC<CommonDialogErrorProps> = (props: CommonDialogErrorProps) => {
  const { errors } = props;
  const classes = useStyles();

  return (
    <>
      { errors && errors.map((error: string) => (
        <Box key={uid()} className={classes.errorContent}>
          <Box className={classes.error}>{ error }</Box>
        </Box>
      ))}
    </>
  );
};

const MemoizedCommonDialogError = React.memo(CommonDialogError);
export default MemoizedCommonDialogError;
