import { useEffect, useState } from 'react';
import { Action, Localization } from 'material-table';
import { TFunction } from 'i18next';

const localizationFn = (translation: TFunction): Localization => ({
  header: {
    actions: translation('table.localization.header.actions'),
  },
  body: {
    emptyDataSourceMessage: translation('table.localization.body.emptyDataSourceMessage'),
    filterRow: {
      filterTooltip: translation('table.localization.filter.tooltip'),
    },
  },
});

// eslint-disable-next-line
const deleteActionFn = <RowData extends object>(deleteClickAction: (event, data: RowData | Array<RowData>) => void, translation: TFunction): Action<RowData> => ({
  icon: 'delete',
  tooltip: translation('table.actions.delete.tooltip'),
  onClick: deleteClickAction,
});

// eslint-disable-next-line
const editActionFn = <RowData extends object>(editClickAction: (event, data: RowData | Array<RowData>) => void, translation: TFunction): Action<RowData> => ({
  icon: 'edit',
  tooltip: translation('table.actions.edit.tooltip'),
  onClick: editClickAction,
});

// eslint-disable-next-line
export const useLogic = <RowData extends object>(
  translation: TFunction,
  onDeleteAction: (event, data: RowData | Array<RowData>) => void | undefined,
  onEditAction: (event, data: RowData | Array<RowData>) => void | undefined,
): {
    actions: Array<Action<RowData>>,
    localization: Localization,
  } => {
  const [actions, setActions] = useState<Array<Action<RowData>> | null>(null);

  useEffect(() => {
    const tableActions: Array<Action<RowData>> = [];

    if (typeof onEditAction === 'function') {
      tableActions.push(editActionFn(onEditAction, translation));
    }

    if (typeof onDeleteAction === 'function') {
      tableActions.push(deleteActionFn(onDeleteAction, translation));
    }

    if (tableActions.length > 0) {
      setActions(tableActions);
    }
  // eslint-disable-next-line
  }, []);

  return {
    actions,
    localization: localizationFn(translation),
  };
};
