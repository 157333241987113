import React, {
  FC, forwardRef, ReactElement, useMemo,
} from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '../../common';

interface ListItemLinkProps {
  icon?: ReactElement;
  primary: string;
  to?: string;
}

const ListItemLink: FC<ListItemLinkProps> = (props: ListItemLinkProps) => {
  const { icon, primary, to } = props;

  const renderLink = useMemo(() => {
    // eslint-disable-next-line
    const renderLinkRef = forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
      <RouterLink to={to} ref={ref} {...itemProps} />
    ));

    renderLinkRef.displayName = 'RenderLink';
    return renderLinkRef;
    // eslint-disable-next-line
  }, [to]);

  return (
    <ListItem button component={renderLink}>
      { icon ? <ListItemIcon>{icon}</ListItemIcon> : null }
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default ListItemLink;
