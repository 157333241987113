import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  appHeader: {
    backgroundColor: '#fff',
  },
  titleContainer: {
    flexGrow: 1,
    marginLeft: 80,
    display: 'flex',
  },
  title: {
    color: '#000',
    fontSize: '1.3rem',
  },
  userTitle: {
    color: '#000',
    fontWeight: 'bold',
  },
  toolbar: {
    padding: '0 16px 0 70px',
  },
  imageContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  brandImage: {
    width: 118,
    height: 51,
  },
}));
