import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AppBar, Box, CardMedia, Toolbar,
} from '../common';

import ProfileMenu from '../profileMenu/ProfileMenu';

import { useLogic } from './hooks/useLogic';

import useStyles from './appBarTopStyles';
import defaultBrandImage from '../../assets/brand.png';

const AppBarTop: FC<unknown> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, onBrandImageClick } = useLogic();

  return (
    <AppBar className={classes.appHeader}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.imageContainer} onClick={onBrandImageClick}>
          <CardMedia
            className={classes.brandImage}
            component="img"
            alt="Room Rocket"
            image={defaultBrandImage}
          />
        </Box>
        <Box className={classes.titleContainer}>
          <Box className={classes.title}>{t('appBarTitle')}</Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box className={classes.userTitle}>{user?.userName || ''}</Box>
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const memoizedAppBarTop = memo(AppBarTop);
export default memoizedAppBarTop;
