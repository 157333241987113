import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '../../common';

import DashboardListItem from '../dashboardListItem/DashboardListItem';

import { MenuItem } from '../../../models';

import { NO_LINK } from '../../../configs/menu';

interface DashboardMenuListProps {
  children?: Array<MenuItem>;
}

const DashboardMenuList: FC<DashboardMenuListProps> = (props: DashboardMenuListProps) => {
  const { children } = props;
  const history = useHistory();
  
  const navigate = (event: MouseEvent<HTMLDivElement>): void => {
    const { link } = event.currentTarget.dataset;
    history.push(link);
  };

  return (
    <>
      { children?.map((menuItem: MenuItem) => (
        <Box key={menuItem.label} display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
          { menuItem.link === NO_LINK && menuItem.children ? (
            <>
              { menuItem.children.map((childItem: MenuItem) => <DashboardListItem key={childItem.label} item={childItem} onNavigate={navigate} />) }
            </>
          ) : (<DashboardListItem item={menuItem} onNavigate={navigate} />) }
        </Box>
      )) }
    </>
  );
};

export default DashboardMenuList;
