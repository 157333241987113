import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, FormControl, FormHelperText, InputLabel, Select, TextField,
} from '../../common';

import CommonDialogButtons from '../../common/commonDialogButtons/CommonDialogButtons';
import CommonDialogError from '../../common/commonDialogError/CommonDialogError';
import CommonTableDialog from '../../common/commonTableDialog/CommonTableDialog';

import { Markup } from '../../../models';

import { useCommonInputLogic } from '../../../hooks/useCommonInputLogic';
import { useValidation } from '../hooks/useValidation';

import useStyles from '../../commonDialogInputStyles/commonDialogInputStyles';

interface AddMarkupRoutineDialogProps {
  data: Markup;
  errors: Array<string> | null;
  isMutating: boolean;
  isOpen: boolean;
  providers: { [key: string]: string; };
  onClose: () => void;
  onSuccess: (data: Markup) => void;
}

const AddMarkupRoutineDialog: FC<AddMarkupRoutineDialogProps> = (props: AddMarkupRoutineDialogProps) => {
  const {
    data, errors, isMutating, isOpen, providers, onClose, onSuccess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutated, onChange, onSubmit } = useCommonInputLogic<Markup>(data, onSuccess);
  const { errorMessage, onValidate } = useValidation(mutated, onSubmit);

  const [providerEntries, setProviderEntries] = useState<Array<[string, string]>>([]);

  useEffect(() => {
    setProviderEntries(Object.entries(providers));
  // eslint-disable-next-line
  }, []);

  return (
    <CommonTableDialog dialogTitle="dialogs.providerMarkup.createTitle" isDialogOpen={isOpen} onClose={onClose}>
      <Box mb="16px">
        { errors && <CommonDialogError errors={errors} /> }
      </Box>
      <form onSubmit={onValidate}>
        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="provider_id">{t('table.columns.providerMarkup.supplier')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'provider_id',
                  name: 'provider_id',
                }}
                onChange={onChange}
                value={data.provider_id}
                label={t('table.columns.providerMarkup.supplier')}
              >
                <option aria-label="None" value="" />
                { providerEntries.map((entry) => (
                  <option key={entry[0]} value={entry[0]}>{entry[1]}</option>
                )) }
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              inputProps={{
                type: 'number',
                min: 0,
                step: '.01',
              }}
              classes={{ root: classes.textfield }}
              name="markup_value"
              onChange={onChange}
              label={t('table.columns.providerMarkup.markupValue')}
              value={data.markup_value}
            />
            <Box ml={1} minHeight="20px">
              { errorMessage && (
                <FormHelperText classes={{ root: classes.helperText }} error={true}>
                  { t(errorMessage) }
                </FormHelperText>
              ) }
            </Box>
          </Box>
        </Box>

        <CommonDialogButtons
          actionButtonTitle={'createBtnTitle'}
          isMutating={isMutating}
          onClose={onClose}
        />

      </form>
    </CommonTableDialog>
  );
};

export default AddMarkupRoutineDialog;
