export default [
  {
    id: 2,
    name: 'EUR',
  },
  {
    id: 1,
    name: 'USD',
  },
];
