import { ChangeEvent, useState } from 'react';

export const useCommonInputLogic = <T>(mutation: T, onSuccess: (mutatedItem: T) => void): {
  mutated: T;
  onChange: (event) => void;
  onSubmit: (event) => void;
} => {
  const [mutated, setMutated] = useState<T>(mutation);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    // eslint-disable-next-line
    let value: string | boolean = event.target.value;

    // because inconsistent naming on the BE side
    if (name === 'active' || name === 'isActive') {
      value = checked;
    }

    setMutated((prev: T) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = (event): void => {
    event.preventDefault();
    onSuccess(mutated);
  };

  return {
    mutated,
    onChange,
    onSubmit,
  };
};
