import { CSSProperties } from 'react';
import { Options } from 'material-table';

// eslint-disable-next-line
const rowStyle = (data: any, index: number, level: number): CSSProperties => {
  let bgColor = null;

  if (index % 2 > 0) {
    bgColor = '#e2e2e2';
  }

  return { backgroundColor: bgColor };
};

// eslint-disable-next-line
const commonTableOptions: Options<any> = {
  paging: false,
  pageSize: 10,
  headerStyle: {
    backgroundColor: '#000',
    fontWeight: 'bold',
    color: '#fff',
  },
  showTitle: false,
  pageSizeOptions: [15, 20],
  toolbar: false,
  sorting: false,
  filtering: false,
  actionsColumnIndex: -1,
  rowStyle,
};

export default commonTableOptions;
