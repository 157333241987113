import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  Box, Collapse, List, ListItem,
} from '../common';

import ListItemLink from './listItemLink/ListItemLink';

import { MenuItem } from '../../models';

import { useLogic } from './hooks/useLogic';

import useStyles from './menuContainerStyles';

const collapseTimeMs = 800;

const MenuContainer: FC<unknown> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isOpenObj, expandCollapseMenuItem, getActiveMenuItems } = useLogic();

  const menuContent = (menuItems: Array<MenuItem>, isChildren?: boolean | undefined): Array<ReactNode> => {
    const listItemClass: string = isChildren ? classes.nested : classes.notNested;

    return menuItems.map((item: MenuItem) => {
      const {
        children, icon, label, link,
      } = item;

      if (!children) {
        return (
          <ListItem
            button
            key={label}
            className={listItemClass}
            selected={pathname === link}
          >
            <ListItemLink to={link} primary={t(label)} icon={icon} />
          </ListItem>
        );
      }
      return (
        <Box key={label}>
          <ListItem
            button
            key={label}
            className={listItemClass}
            selected={pathname === link}
            onClick={expandCollapseMenuItem}
            data-menuitem={label}
          >
            <ListItemLink to={link} primary={t(label)} icon={icon} />
            { !isOpenObj[label] ? <ExpandLess /> : <ExpandMore /> }
          </ListItem>
          <Collapse in={!isOpenObj[label]} timeout={collapseTimeMs} unmountOnExit>
            <List component="div" disablePadding>{menuContent(children, true)}</List>
          </Collapse>
        </Box>
      );
    });
  };

  return (
    <List component="nav" className={classes.root}>{menuContent(getActiveMenuItems())}</List>
  );
};

export default MenuContainer;
