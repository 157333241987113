import {
  ChangeEvent,
  FormEvent,
  useContext,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import UserContext, { UserContextModel } from '../../../../contexts/UserContext';
import { userRemote } from '../../../../api/services';

import { User } from '../../../../models';

import { HOME_ROUTE_PATH } from '../../../../routes/routesPath';

export const useLogic = (): {
  errorMessage: string,
  isLoading: boolean,
  password: string,
  username: string,
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
} => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setUser } = useContext<UserContextModel>(UserContext);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onLoginSuccess = (data: User): void => {
    setUser(data);
    history.push(HOME_ROUTE_PATH);
  };

  const { isLoading, mutateAsync } = useMutation(userRemote.authenticate, { onSuccess: onLoginSuccess });

  const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    switch (name) {
      case 'username': setUsername(value); break;
      case 'password': setPassword(value); break;
      default: break;
    }

    if (value && errorMessage) {
      setErrorMessage('');
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      setErrorMessage('');
      await mutateAsync({ username, password });
    } catch (error) {
      if (error.response?.data?.errors[0] === 'Incorrect username or password') {
        setErrorMessage(t('incorrectUsernameOrPassword'));
      }
    }
  };

  return {
    errorMessage,
    isLoading,
    password,
    username,
    onInputChange,
    onSubmit,
  };
};
