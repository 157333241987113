import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  commonUserContainer: {
    minHeight: '100%',
    padding: 15,
  },
  headerContainer: {
    display: 'flex',
  },
  buttonContainer: {
    marginRight: 8,
    width: '10%',
  },
  titleContainer: {
    display: 'flex',
    width: '80%',
    justifyContent: 'center',
  },
  card: {
    margin: '8px 0',
    padding: 0,
    backgroundColor: '#fffa',
    boxShadow: '2px 2px 12px 5px #fffc',
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));
