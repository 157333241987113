import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, FormControl, InputLabel, Select,
} from '../../common';

import CommonDialogButtons from '../../common/commonDialogButtons/CommonDialogButtons';
import CommonDialogError from '../../common/commonDialogError/CommonDialogError';
import CommonTableDialog from '../../common/commonTableDialog/CommonTableDialog';

import { ProviderSupplierPair } from '../../../models';

import { useCommonInputLogic } from '../../../hooks/useCommonInputLogic';

import useStyles from '../../commonDialogInputStyles/commonDialogInputStyles';

interface AddProviderSupplierDialogProps {
  apiClients: { [key: string]: string; };
  errors: Array<string> | null;
  isMutating: boolean;
  isOpen: boolean;
  pairData: ProviderSupplierPair;
  providers: { [key: string]: string; };
  onClose: () => void;
  onSuccess: (pair: ProviderSupplierPair) => void;
}

const AddProviderSupplierDialog: FC<AddProviderSupplierDialogProps> = (props: AddProviderSupplierDialogProps) => {
  const {
    apiClients, errors, isMutating, isOpen, pairData, providers, onClose, onSuccess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { onChange, onSubmit } = useCommonInputLogic<ProviderSupplierPair>(pairData, onSuccess);

  const [apiClientEntries, setApiClientEntries] = useState<Array<[string, string]>>([]);
  const [providerEntries, setProviderEntries] = useState<Array<[string, string]>>([]);

  useEffect(() => {
    setApiClientEntries(Object.entries(apiClients));
    setProviderEntries(Object.entries(providers));
  // eslint-disable-next-line
  }, []);

  return (
    <CommonTableDialog dialogTitle="dialogs.providerSupplier.createTitle" isDialogOpen={isOpen} onClose={onClose}>
      <Box mb="16px">
        { errors && <CommonDialogError errors={errors} /> }
      </Box>
      <form onSubmit={onSubmit}>
        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="apiClientId">{t('table.columns.providerSupplier.ota')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'apiClientId',
                  name: 'apiClientId',
                }}
                onChange={onChange}
                value={pairData.apiClientId}
                label={t('table.columns.providerSupplier.ota')}
              >
                <option aria-label="None" value="" />
                { apiClientEntries.map((entry) => (
                  <option key={entry[0]} value={entry[0]}>{entry[1]}</option>
                )) }
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="providerId">{t('table.columns.providerSupplier.supplier')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'providerId',
                  name: 'providerId',
                }}
                onChange={onChange}
                label={t('table.columns.providerSupplier.supplier')}
                value={pairData.providerId}
              >
                <option aria-label="None" value="" />
                { providerEntries.map((entry) => (
                  <option key={entry[0]} value={entry[0]}>{entry[1]}</option>
                )) }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <CommonDialogButtons
          actionButtonTitle={'createBtnTitle'}
          isMutating={isMutating}
          onClose={onClose}
        />

      </form>
    </CommonTableDialog>
  );
};

export default AddProviderSupplierDialog;
