import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent } from '../../common';

import { MenuItem } from '../../../models';

import useStyles from './dashboardListItemStyles';

interface DashboardListItemProps {
  item: MenuItem;
  onNavigate: (event: MouseEvent<HTMLDivElement>) => void;
}

const DashboardListItem: FC<DashboardListItemProps> = (props: DashboardListItemProps) => {
  const { item, onNavigate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Card className={classes.card} data-link={item.link} onClick={onNavigate}>
      <CardContent className={classes.cardContent}>
        { item.icon }
        <Box className={classes.tempStyles}>{t(item.label)}</Box>
      </CardContent>
    </Card>
  );
};

export default DashboardListItem;
