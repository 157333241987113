import api, { handleApiError } from '../apiService';

const providerRemote = {
  getProviderList: async (): Promise<{ [key: string]: string; }> => {
    try {
      const { data } = await api.get('codification/providers');
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default providerRemote;
