// Make sure react-hot-loader is required before react and react-dom
import { hot } from 'react-hot-loader/root';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from './routes/Routes';

import { UserContext } from './contexts';
import { useSessionStorage } from './hooks/useSessionStorage';

const App: React.FC = () => {
  const [user, setUser] = useSessionStorage('user', null);
  
  const memoizedUserValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    setUser(user);
    // eslint-disable-next-line
  }, [user]);

  return (
    <BrowserRouter>
      <CssBaseline />
      <UserContext.Provider value={memoizedUserValue}>
        <Routes />
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default hot(App);
