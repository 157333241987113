import { useQuery } from 'react-query';

import { providerRemote } from '../../api/services';

export const useProviderMapping = (): { providerMapping: { [key: string]: string; }; } => {
  const {
    data: providerMapping,
  } = useQuery('providerMapping', providerRemote.getProviderList, {
    cacheTime: 0,
    initialData: null,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  return {
    providerMapping,
  };
};
