import {
  MouseEvent, MutableRefObject, useRef, useState,
} from 'react';

const INPUT_TYPE_PASSWORD = 'password';

export const usePasswordToggle = (): {
  isPasswordType: boolean,
  passwordInputRef: MutableRefObject<HTMLInputElement>,
  toggleInputType: string,
  onToggleVisibility: (event: MouseEvent<HTMLButtonElement>) => void;
} => {
  const [toggleInputType, setToggleInputType] = useState<string>(INPUT_TYPE_PASSWORD);

  const passwordInputRef: MutableRefObject<HTMLInputElement> = useRef<HTMLInputElement>();

  // eslint-disable-next-line
  const onToggleVisibility = (event: MouseEvent<HTMLButtonElement>): void => {
    let inputType = passwordInputRef.current.type;

    if (inputType === INPUT_TYPE_PASSWORD) {
      inputType = 'text';
    } else {
      inputType = INPUT_TYPE_PASSWORD;
    }

    setToggleInputType(inputType);
  };

  return {
    isPasswordType: toggleInputType === INPUT_TYPE_PASSWORD,
    passwordInputRef,
    toggleInputType,
    onToggleVisibility,
  };
};

export default usePasswordToggle;
