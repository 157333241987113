import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { blacklistRemote } from '../../api/services';

import AddProviderSupplierDialog from './addDialog/AddProviderSupplierDialog';
import CommonTableContainer from '../common/commonTableContainer/CommonTableContainer';
import DeleteConfirmationDialog from '../common/deleteConfirmationDialog/DeleteConfirmationDialog';
import Pagination from '../common/pagination/Pagination';
import Table from '../table/Table';

import { ProviderSupplierPair } from '../../models';

import { useApiClientMapping } from '../../hooks/useApiClientMapping';
import { useCommonTableLogic, UseCommonContainerLogicParams } from '../../hooks/useCommonTableLogic';
import { useProviderMapping } from '../../hooks/useProviderMapping';

import columns from '../../configs/tablesColumns/providerSupplierColumns';
import commonTableOptions from '../../configs/tableOptions';

const useLogicParams: UseCommonContainerLogicParams<ProviderSupplierPair> = {
  emptyModel: { } as ProviderSupplierPair,
  queryKey: 'providerSupplier',
  createMutationFunction: blacklistRemote.createBlacklistPair,
  deleteMutationFunction: blacklistRemote.deleteBlacklistPair,
  queryFunction: blacklistRemote.getBlacklistPairs,
};

const ProviderSupplierConfContainer: FC<unknown> = () => {
  const { t } = useTranslation();

  const {
    apiList, isFetching, isMutating, mutateErrors, mutateData, openConfirmationDialog, openDialog, pageOn,
    onCreate, onDelete, onDialogClose, onPageChange, onDeleteSuccess, onSuccess,
  } = useCommonTableLogic<ProviderSupplierPair>(useLogicParams);

  const { apiClientMapping } = useApiClientMapping();
  const { providerMapping } = useProviderMapping();

  return (
    <CommonTableContainer
      addEditDialog={(
        <AddProviderSupplierDialog
          apiClients={apiClientMapping}
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openDialog}
          pairData={mutateData}
          providers={providerMapping}
          onClose={onDialogClose}
          onSuccess={onSuccess}
        />
      )}
      confirmationDialog={(
        <DeleteConfirmationDialog
          entity={mutateData}
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openConfirmationDialog}
          title="dialogs.providerSupplier.deleteConfirmation"
          onClose={onDialogClose}
          onSuccess={onDeleteSuccess}
        >
          { mutateData ? (
            <>
              { t('dialogs.providerSupplier.deleteConfirmationText', {
                ota: apiClientMapping ? apiClientMapping[mutateData.apiClientId] : mutateData.apiClientId,
                supplier: providerMapping ? providerMapping[mutateData.providerId] : mutateData.providerId,
              }) }
            </>
          ) : null }
        </DeleteConfirmationDialog>
      )}
      headerTitle="providerSupplier.header.providerSupplier"
      isConfirmationOpen={openConfirmationDialog}
      isDialogOpen={openDialog}
      pagination={(
        <Pagination
          forcePage={pageOn}
          paginatedPage={apiList.number}
          totalElements={apiList.totalElements}
          totalPages={apiList.totalPages}
          onPageChange={onPageChange}
        />
      )}
      table={(
        <Table
          columns={columns(t, apiClientMapping, providerMapping)}
          data={apiList.content}
          isLoading={isFetching}
          options={commonTableOptions}
          onDeleteAction={onDelete}
        />
      )}
      onAddButtonClick={onCreate}
    />
  );
};

export default ProviderSupplierConfContainer;
