import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  rowItem: {
    width: '45%',
    marginBottom: 10,
  },
  textfield: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000',
    },
    '& .MuiInputBase-root .MuiOutlinedInput-root .Mui-focused': {
      borderColor: '#000',
    },
  },
  copyIconButton: {
    padding: '0 0 0 8px',
    '&:hover': {
      color: '#000',
      backgroundColor: 'transparent',
    },
  },
  copyIcon: {
    fontSize: 30,
    transform: 'rotate(90deg)',
  },
  visibilityIconButton: {
    padding: '0 0 0 8px',
    '&:hover': {
      color: '#000',
      backgroundColor: 'transparent',
    },
  },
  visibilityIconButtonClicked: {
    padding: '0 0 0 8px',
    color: '#000',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'transparent',
    },
  },
  visibilityIcon: {
    fontSize: 30,
  },
  passwordHelperText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    textAlign: 'left',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    paddingLeft: 5,
  },
  helperText: {
    marginTop: 0,
  },
}));
