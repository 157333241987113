import axios from 'axios';
import headers from './headers';
import baseUrl from './baseUrl';

import EnvironmentType from '../models/EnvironmentType.enum';

import { HOME_ROUTE_PATH, LOGIN_ROUTE_PATH } from '../routes/routesPath';
import configs from '../configs/environments';

interface ApiService {
  // eslint-disable-next-line
  post: (path: string, data?: any) => Promise<any>;
  // eslint-disable-next-line
  get: (path: string) => Promise<any>;
  // eslint-disable-next-line
  put: (path: string, data?: any) => Promise<any>;
  // eslint-disable-next-line
  delete: (path: string, data?: any) => Promise<any>;
}

const api: ApiService = {

  post: (path: string, data?) => axios({
    method: 'post',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  }),

  get: (path: string) => axios({
    method: 'get',
    url: baseUrl + path,
    headers,
    withCredentials: true,
  }),

  put: (path: string, data?) => axios({
    method: 'put',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  }),

  delete: (path: string, data?) => axios({
    method: 'delete',
    url: baseUrl + path,
    headers,
    withCredentials: true,
    data: JSON.stringify(data),
  }),

};

// eslint-disable-next-line
export const handleApiError = (error: any): Promise<any> => {
  if (!error.response) {
    throw error;
  }

  const errorCode: number = error.response.status;
  const errorMessage: string = error.response.data.message;

  if (errorCode === 400) {
    // API return error messages like: error.response.data.errors as Array<string>
    return Promise.reject(error.response.data.errors);
  }

  if (errorCode === 403) {
    if (configs.envType !== EnvironmentType.PROD) {
      console.log('FORBIDDEN ERROR: ', errorMessage);
    }

    window.location.href = LOGIN_ROUTE_PATH;
  }

  if (errorCode === 500) {
    window.location.href = HOME_ROUTE_PATH;
  }

  if (configs.envType !== EnvironmentType.PROD) {
    console.log(`ERROR: CODE: ${errorCode}, message: ${error.message}.`);
  }

  return Promise.reject(error);
};

export default api;
