import { Column } from 'material-table';
import { TFunction } from 'i18next';

import { AdminUser } from '../../models';

import activeColumnRenderer from '../../utils/activeColumnRenderer';

const columns = (translation: TFunction): Array<Column<AdminUser>> => [
  {
    title: translation('table.columns.adminUser.userName'),
    field: 'userName',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.adminUser.email'),
    field: 'email',
    cellStyle: { minWidth: 250 },
  },
  {
    title: translation('table.columns.adminUser.status'),
    field: 'active',
    cellStyle: { minWidth: 50 },
    render: ({ active }) => activeColumnRenderer(active, translation),
  },
];

export default columns;
