import React, { FC, MouseEvent } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Button } from '..';

const useStyles = makeStyles(() => createStyles({
  addButton: {
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #000',
    boxShadow: '2px 2px 8px 0px #fffc',
    '&:hover': {
      backgroundColor: '#fffd',
      boxShadow: null,
    },
  },
}));

interface RrButtonProps {
  title: string;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  onButtonClicked: (event: MouseEvent<HTMLButtonElement>) => void;
}

const RrButton: FC<RrButtonProps> = (props: RrButtonProps) => {
  const {
    title, endIcon, startIcon, onButtonClicked,
  } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.addButton}
      onClick={onButtonClicked}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {title}
    </Button>
  );
};

export default RrButton;
