import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import HomeContainer from '../components/home/HomeContainer';
import LoginContainer from '../components/login/LoginContainer';
import PrivateRoute from './PrivateRoute';

import { LOGIN_ROUTE_PATH } from './routesPath';

const Routes: FC<unknown> = () => (
  <Switch>
    <Route path={LOGIN_ROUTE_PATH} component={LoginContainer} />
    <PrivateRoute component={HomeContainer} />
  </Switch>
);

export default Routes;
