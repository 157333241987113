import { Environment } from '../models';
import EnvironmentType from '../models/EnvironmentType.enum';

export const PAGINATION_TABLE_SIZE = 12;

// env specific
const PROTOCOL_LOCAL = 'http';
const URL_LOCAL = 'localhost';
const PORT_LOCAL = '8080';

const PROTOCOL_DEV = 'https';
const URL_DEV = 'roomrocket-admin-be.us-east-2.elasticbeanstalk.com';

const PROTOCOL_PROD = '';
const URL_PROD = '';

const local: Environment = {
  api: {
    protocol: PROTOCOL_LOCAL,
    url: URL_LOCAL,
    port: PORT_LOCAL,
  },
  envType: EnvironmentType.LOCAL,
};

const dev: Environment = {
  api: {
    protocol: PROTOCOL_DEV,
    url: URL_DEV,
  },
  envType: EnvironmentType.DEV,
};

const prod: Environment = {
  api: {
    protocol: PROTOCOL_PROD,
    url: URL_PROD,
  },
  envType: EnvironmentType.PROD,
};

const processEnv: { env: Environment } = { env: dev };

switch (process.env.REACT_APP_ENV) {
  case EnvironmentType.DEV: processEnv.env = dev; break;
  case EnvironmentType.LOCAL: processEnv.env = local; break;
  case EnvironmentType.PROD: processEnv.env = prod; break;
  default: processEnv.env = dev; break;
}

export default processEnv.env;
