import { useQuery } from 'react-query';

import { countryRemote } from '../../api/services';

export const useCountryMapping = (): { countryMapping: { [key: string]: string; }; } => {
  const {
    data: countryMapping,
  } = useQuery('countryMapping', countryRemote.getAllCountryCodePairs, {
    initialData: null,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    cacheTime: 0,
  });

  return {
    countryMapping,
  };
};
