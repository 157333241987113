import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '../common';

import DashboardMenuList from './menuList/DashboardMenuList';
import PageHeader from '../common/pageHeader/PageHeader';

import { MenuItem } from '../../models';

import menuItems from '../../configs/menu';

const useStyles = makeStyles(() => createStyles({
  dashboardContainer: {
    overflow: 'auto',
    height: '100%',
    padding: '15px 40px',
  },
}));

// TODO: temp, need to implement logic similar to getActiveMenuItems() in MenuContainer
const menuItemsClone: Array<MenuItem> = cloneDeep(menuItems);

const DashboardContainer: FC<unknown> = () => {
  const classes = useStyles();
  
  const { t } = useTranslation();
  
  return (
    <Box className={classes.dashboardContainer}>
      <PageHeader>{t('dashboard.header.text')}</PageHeader>
      <DashboardMenuList>{menuItemsClone}</DashboardMenuList>
    </Box>
  );
};

export default DashboardContainer;
