import api, { handleApiError } from '../apiService';

import { ApiList, PortalUser, UserRole } from '../../models';

import { PAGINATION_TABLE_SIZE } from '../../configs/environments';

const transformToApiAcceptableFormat = (portalUser: PortalUser): PortalUser => {
  const { currency, roles, unit } = portalUser;

  let transformedCurrency;
  let transformedUnit;

  // value of currency could be: `string` or `{ id: number; name: string; }` if input is not touched
  if (currency && typeof currency === 'object' && 'name' in currency) {
    transformedCurrency = currency.name;
  } else {
    transformedCurrency = currency || null;
  }

  // value of unit could be: `string` or `{ id: number; name: string; }` if input is not touched
  if (unit && typeof unit === 'object' && 'name' in unit) {
    transformedUnit = unit.name;
  } else {
    transformedUnit = unit || null;
  }

  return {
    ...portalUser,
    currency: transformedCurrency, // the BE is still inconsistent about this
    roles: (roles as Array<UserRole>)[0].name, // BE is still inconsistent about this
    unit: transformedUnit, // BE is still inconsistent about this
  };
};

const portalUserRemote = {
  getPortalUsers: async (page: number): Promise<ApiList<PortalUser>> => {
    try {
      const { data } = await api.get(`user/getAll?size=${PAGINATION_TABLE_SIZE}&page=${page}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  createPortalUser: async (newPortalUser: PortalUser): Promise<PortalUser> => {
    try {
      const { data } = await api.post('user/add', newPortalUser);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  updatePortalUser: async (portalUser: PortalUser): Promise<PortalUser> => {
    try {
      const { data } = await api.put(`user/update/${portalUser.id}`, transformToApiAcceptableFormat(portalUser));
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default portalUserRemote;
