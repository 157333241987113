import { Column } from 'material-table';
import { TFunction } from 'i18next';

import { ApiUser } from '../../models';

import activeColumnRenderer from '../../utils/activeColumnRenderer';

const columns = (translation: TFunction, countryMapping: { [key: string]: string; }): Array<Column<ApiUser>> => [
  {
    title: translation('table.columns.apiUser.companyName'),
    field: 'companyName',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.companyPhone'),
    field: 'companyPhone',
    cellStyle: { minWidth: 150 },
  },
  {
    title: translation('table.columns.apiUser.companyEmail'),
    field: 'companyEmail',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.currencyCode'),
    field: 'currencyCode',
    cellStyle: { minWidth: 50 },
  },
  {
    title: translation('table.columns.apiUser.countryCode'),
    field: 'countryCode',
    cellStyle: { minWidth: 200 },
    render: ({ countryCode }) => {
      if (!countryMapping) return countryCode;

      return countryMapping[countryCode];
    },
  },
  {
    title: translation('table.columns.apiUser.additionalInfo'),
    field: 'additionalInfo',
    cellStyle: { minWidth: 250 },
  },
  {
    title: translation('table.columns.apiUser.bankDetails'),
    field: 'bankDetails',
    cellStyle: { minWidth: 250 },
  },
  {
    title: translation('table.columns.apiUser.distributorContactTitle'),
    field: 'distributorContactTitle',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.distributorContactName'),
    field: 'distributorContactName',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.iataNumber'),
    field: 'iataNumber',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.atolNumber'),
    field: 'atolNumber',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.apiUser.status'),
    field: 'active',
    cellStyle: { minWidth: 50 },
    render: ({ active }) => activeColumnRenderer(active, translation),
  },
];

export default columns;
