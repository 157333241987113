import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import UserContext, { UserContextModel } from '../../../../contexts/UserContext';

import { User } from '../../../../models';

import { HOME_ROUTE_PATH } from '../../../../routes/routesPath';

export const useLogic = (): {
  user: User | null;
  onBrandImageClick: () => void;
} => {
  const history = useHistory();
  const { user } = useContext<UserContextModel>(UserContext);

  const onBrandImageClick = (): void => {
    history.push(HOME_ROUTE_PATH);
  };

  return {
    user,
    onBrandImageClick,
  };
};
