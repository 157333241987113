import React, { FC } from 'react';

import { Box, LinearProgress } from '..';

interface ProgressIconProps {
  isFetching: boolean;
}

const ProgressIcon: FC<ProgressIconProps> = (
  props: ProgressIconProps,
) => {
  const { isFetching } = props;

  if (isFetching) {
    return (
      <Box height="24px" width="55px" display="flex" alignItems="center">
        <Box width="100%">
          <LinearProgress color="primary" />
        </Box>
      </Box>
    );
  }

  return null;
};

export default ProgressIcon;
