import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { apiUserRemote } from '../../../api/services';

import AddEditApiUserDialog from './addEditDialog/AddEditApiUserDialog';
import CommonTableContainer from '../../common/commonTableContainer/CommonTableContainer';
import Pagination from '../../common/pagination/Pagination';
import Table from '../../table/Table';

import ApiUser, { getEmptyApiUser } from '../../../models/ApiUser';

import { useCommonTableLogic, UseCommonContainerLogicParams } from '../../../hooks/useCommonTableLogic';
import { useCountryMapping } from '../../../hooks/useCountryMapping';

import columns from '../../../configs/tablesColumns/apiUserColumns';
import commonTableOptions from '../../../configs/tableOptions';

const useLogicParams: UseCommonContainerLogicParams<ApiUser> = {
  emptyModel: getEmptyApiUser(),
  queryKey: 'apiUsers',
  createMutationFunction: apiUserRemote.createApiUser,
  queryFunction: apiUserRemote.getApiUsers,
  updateMutationFunction: apiUserRemote.updateApiUser,
};

const ApiUserContainer: FC<unknown> = () => {
  const { t } = useTranslation();

  const {
    apiList, isFetching, isMutating, mutateErrors, mutateData, openDialog, pageOn,
    onCreate, onDialogClose, onPageChange, onSuccess, onUpdate,
  } = useCommonTableLogic<ApiUser>(useLogicParams);

  const { countryMapping } = useCountryMapping();

  return (
    <CommonTableContainer
      addEditDialog={(
        <AddEditApiUserDialog
          countries={countryMapping}
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openDialog}
          userData={mutateData}
          onClose={onDialogClose}
          onSuccess={onSuccess}
        />
      )}
      headerTitle="userManagement.header.apiUsers"
      isDialogOpen={openDialog}
      pagination={(
        <Pagination
          forcePage={pageOn}
          paginatedPage={apiList.number}
          totalElements={apiList.totalElements}
          totalPages={apiList.totalPages}
          onPageChange={onPageChange}
        />
      )}
      table={(
        <Table
          columns={columns(t, countryMapping)}
          data={apiList.content}
          isLoading={isFetching}
          options={commonTableOptions}
          onEditAction={onUpdate}
        />
      )}
      onAddButtonClick={onCreate}
    />
  );
};

export default ApiUserContainer;
