export default interface ApiUser {
  active: boolean;
  additionalInfo: string;
  apiKey: string;
  apiSecret: string;
  atolNumber: string;
  bankDetails: string;
  companyEmail: string;
  companyId?: number;
  companyName: string;
  companyPhone: string;
  countryCode: string;
  currencyCode: string;
  distributorContactName: string;
  distributorContactTitle: string;
  iataNumber: string;
  isActive: boolean;
  /**
   * `material-table` modifies its own props (which is a very ugly anti-pattern) to track row selection
   */
  tableData?: { id: number };
}

export function getEmptyApiUser(): ApiUser {
  return {
    active: true,
    additionalInfo: '',
    apiKey: '',
    apiSecret: '',
    atolNumber: '',
    bankDetails: '',
    companyEmail: '',
    companyName: '',
    companyPhone: '',
    countryCode: '',
    currencyCode: '',
    distributorContactName: '',
    distributorContactTitle: '',
    iataNumber: '',
    isActive: true,
  };
}
