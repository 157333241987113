import { Column } from 'material-table';
import { TFunction } from 'i18next';

import { Markup } from '../../models';

const columns = (translation: TFunction, providerMapping: { [key: string]: string; }): Array<Column<Markup>> => [
  {
    title: translation('table.columns.providerMarkup.supplier'),
    field: 'provider_id',
    cellStyle: { minWidth: 250 },
    render: ({ provider_id }) => {
      if (!providerMapping) return provider_id;

      return providerMapping[provider_id];
    },
  },
  {
    title: translation('table.columns.providerMarkup.markupValue'),
    field: 'markup_value',
    cellStyle: { minWidth: 50 },
  },
];

export default columns;
