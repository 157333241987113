import { Column } from 'material-table';
import { TFunction } from 'i18next';

import { ProviderSupplierPair } from '../../models';

const columns = (
  translation: TFunction,
  apiClientMapping: { [key: string]: string; },
  providerMapping: { [key: string]: string; },
): Array<Column<ProviderSupplierPair>> => [
  {
    title: translation('table.columns.providerSupplier.ota'),
    field: 'apiClientId',
    cellStyle: { minWidth: 250 },
    render: ({ apiClientId }) => {
      if (!apiClientMapping) return apiClientId;

      return apiClientMapping[apiClientId];
    },
  },
  {
    title: translation('table.columns.providerSupplier.supplier'),
    field: 'providerId',
    cellStyle: { minWidth: 250 },
    render: ({ providerId }) => {
      if (!providerMapping) return providerId;

      return providerMapping[providerId];
    },
  },
];

export default columns;
