import UserRole from './UserRole';
import UserRoleType from './UserRoleType.enum';

export default interface PortalUser {
  active: boolean;
  currency: {
    id: number;
    name: string;
  };
  email: string;
  id?: number;
  password?: string;
  otaName: string;
  roles: Array<UserRole> | string; // BE is still inconsistent about this
  /**
   * `material-table` modifies its own props (which is a very ugly anti-pattern) to track row selection
   */
  tableData?: { id: number };
  unit: {
    id: number;
    name: string;
  } | string; // BE is still inconsistent about this
  userName: string;
}

export function getEmptyPortalUser(): PortalUser {
  return {
    active: true,
    currency: null,
    email: '',
    password: '',
    otaName: '',
    roles: [{ // no BE defined yet
      id: 1,
      name: UserRoleType.ADMIN,
    }],
    unit: null,
    userName: '',
  };
}
