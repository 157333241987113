import api, { handleApiError } from '../apiService';

import { User } from '../../models';

const userRemote = {
  authenticate: async (credentials: { username: string, password: string }): Promise<User> => {
    try {
      const { data } = await api.post('adminuser/authenticate', credentials);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  signOut: async (): Promise<string> => {
    try {
      const { data } = await api.get('adminuser/logout');
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default userRemote;
