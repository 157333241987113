import { SyntheticEvent, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';

import { MenuItem } from '../../../../models';

import menuItems from '../../../../configs/menu';

export const useLogic = (): {
  // eslint-disable-next-line
  isOpenObj: any;
  expandCollapseMenuItem: (event: SyntheticEvent<HTMLDivElement> | undefined) => void;
  getActiveMenuItems: () => Array<MenuItem>,
} => {
  // eslint-disable-next-line
  const [isOpenObj, setIsOpenObj] = useState<any>({});
  const menuItemsClone: Array<MenuItem> = cloneDeep(menuItems);

  const expandCollapseMenuItem = (event: SyntheticEvent<HTMLDivElement> | undefined): void => {
    if (!event) return;

    const { menuitem } = event.currentTarget.dataset;
    if (!menuitem) return;

    const isOpenCopy = { ...isOpenObj };

    if (menuitem in isOpenCopy) {
      isOpenCopy[menuitem] = !isOpenCopy[menuitem];
    } else {
      isOpenCopy[menuitem] = true;
    }

    setIsOpenObj(isOpenCopy);
  };

  const getActiveMenuItems = (): Array<MenuItem> => (
    menuItemsClone.filter((menuItem: MenuItem) => {
      // TODO: display or hide parent menu item logic
      const isActive = true;

      if (isActive && menuItem.children) {
        // TODO: display or hide parent menu item logic
        // eslint-disable-next-line
        menuItem.children = menuItem.children.filter((childItem: MenuItem) => true);
      }

      return isActive;
    })
  );

  return {
    isOpenObj,
    expandCollapseMenuItem,
    getActiveMenuItems,
  };
};
