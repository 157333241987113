import React from 'react';

import { Box } from '..';

import CommonDialogButtons from '../commonDialogButtons/CommonDialogButtons';
import CommonDialogError from '../commonDialogError/CommonDialogError';
import CommonTableDialog from '../commonTableDialog/CommonTableDialog';

import { useCommonInputLogic } from '../../../hooks/useCommonInputLogic';

// eslint-disable-next-line
interface DeleteConfirmationDialogProps<T extends object> {
  entity: T;
  errors: Array<string> | null;
  isMutating: boolean;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSuccess: (entity: T) => void;
  children?: JSX.Element | Array<JSX.Element>;
}

// eslint-disable-next-line
const DeleteConfirmationDialog = <T extends object>(props: DeleteConfirmationDialogProps<T>) => {
  const {
    entity, errors, isMutating, isOpen, title, onClose, onSuccess, children,
  } = props;

  const { onSubmit } = useCommonInputLogic<T>(entity, onSuccess);

  return (
    <CommonTableDialog dialogTitle={title} isDialogOpen={isOpen} onClose={onClose}>
      <Box mb={2}>{ errors && <CommonDialogError errors={errors} /> }</Box>
      <Box mb={4} fontSize="16px">{ children }</Box>
      <form onSubmit={onSubmit}>
        <CommonDialogButtons
          actionButtonTitle={'deleteBtnTitle'}
          isMutating={isMutating}
          onClose={onClose}
        />
      </form>
    </CommonTableDialog>
  );
};

export default DeleteConfirmationDialog;
