import api, { handleApiError } from '../apiService';

import { ApiList, ApiUser } from '../../models';

import { PAGINATION_TABLE_SIZE } from '../../configs/environments';

const apiUserRemote = {
  getApiUsers: async (page: number): Promise<ApiList<ApiUser>> => {
    try {
      const { data } = await api.get(`apiclient/getAll?size=${PAGINATION_TABLE_SIZE}&page=${page}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  getApiClientList: async (): Promise<{ [key: string]: string; }> => {
    try {
      const { data } = await api.get('codification/apiclients');
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  createApiUser: async (newApiUser: ApiUser): Promise<ApiUser> => {
    try {
      const { data } = await api.post('apiclient/add', newApiUser);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  updateApiUser: async (apiUser: ApiUser): Promise<ApiUser> => {
    try {
      const { data } = await api.put(`apiclient/update/${apiUser.companyId}`, apiUser);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default apiUserRemote;
