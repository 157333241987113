import { createContext } from 'react';

import { User } from '../models';

export interface UserContextModel {
  user: User | null;
  setUser: (user: User | null) => void;
}

const UserContext = createContext<UserContextModel>({ user: null, setUser: () => null });

export default UserContext;
