import { MouseEvent, Reducer, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import {
  QueryObserverResult, RefetchOptions, useMutation, UseMutationOptions, useQuery,
} from 'react-query';

import ApiList, { getEmptyApiList } from '../../models/ApiList';

import { HOME_ROUTE_PATH } from '../../routes/routesPath';

const FIRST_PAGE = 0;

enum ActionType {
  SET_DIALOG_CLOSED = 'SET_DIALOG_CLOSED',
  SET_MUTATE_ERRORS = 'SET_MUTATE_ERRORS',
  SET_MUTATE_ERRORS_INITIAL = 'SET_MUTATE_ERRORS_INITIAL',
  SET_MUTATE_DATA = 'SET_MUTATE_DATA',
  SET_MUTATE_DELETE_DATA = 'SET_MUTATE_DELETE_DATA',
  SET_ON_PAGE = 'SET_ON_PAGE',
}

interface State<T> {
  mutateData: T | null;
  mutateErrors: Array<string> | null;
  openConfirmationDialog: boolean;
  openDialog: boolean;
  pageOn: number;
}

const initialState = {
  mutateData: null,
  mutateErrors: null,
  openConfirmationDialog: false,
  openDialog: false,
  pageOn: FIRST_PAGE,
};

type Action<T> =
  | { type: ActionType.SET_DIALOG_CLOSED }
  | { type: ActionType.SET_MUTATE_ERRORS, mutateErrors: Array<string> }
  | { type: ActionType.SET_MUTATE_ERRORS_INITIAL }
  | { type: ActionType.SET_MUTATE_DATA, mutateData: T | null }
  | { type: ActionType.SET_MUTATE_DELETE_DATA, mutateData: T | null }
  | { type: ActionType.SET_ON_PAGE, pageOn: number };

const commonLogicReducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case ActionType.SET_DIALOG_CLOSED: return {
      ...state,
      openConfirmationDialog: false,
      openDialog: false,
    };
    case ActionType.SET_MUTATE_ERRORS: return {
      ...state,
      mutateErrors: action?.mutateErrors ? [...action.mutateErrors] : null,
    };
    case ActionType.SET_MUTATE_ERRORS_INITIAL: return {
      ...state,
      mutateErrors: null,
    };
    case ActionType.SET_MUTATE_DATA: return {
      ...state,
      openDialog: true,
      mutateData: { ...action.mutateData },
    };
    case ActionType.SET_MUTATE_DELETE_DATA: return {
      ...state,
      openConfirmationDialog: true,
      mutateData: { ...action.mutateData },
    };
    case ActionType.SET_ON_PAGE: return {
      ...state,
      pageOn: action.pageOn,
    };
    default: return state;
  }
};

export interface UseCommonContainerLogicParams<T> {
  emptyModel: T;
  queryKey: string;
  createMutationFunction?: (newEntity: T) => Promise<T>,
  deleteMutationFunction?: (deletedEntry: T) => Promise<T>,
  updateMutationFunction?: (updatedEntity: T) => Promise<T>,
  queryFunction: (page?: number) => Promise<ApiList<T>>;
}

export const useCommonTableLogic = <T>(params: UseCommonContainerLogicParams<T>): {
  apiList: ApiList<T>;
  isFetching: boolean;
  isMutating: boolean;
  mutateErrors: Array<string> | null;
  mutateData: T;
  openConfirmationDialog: boolean;
  openDialog: boolean;
  pageOn: number;
  onCreate: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelete: (event, rowData: T | Array<T>) => void;
  onDialogClose: () => void;
  onPageChange: (selectedItem: { selected: number; }) => void;
  onDeleteSuccess: (mutated: T) => void;
  onSuccess: (mutated: T) => void;
  onUpdate: (event, rowData: T | Array<T>) => void;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ApiList<T>, unknown>>;
} => {
  const {
    emptyModel, queryKey,
    createMutationFunction, deleteMutationFunction, updateMutationFunction, queryFunction,
  } = params;
  const history = useHistory();

  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(commonLogicReducer, initialState);
  const {
    mutateData, mutateErrors, openConfirmationDialog, openDialog, pageOn,
  } = state;

  const {
    data: apiList, isFetching, isError, refetch,
  } = useQuery([queryKey, pageOn], () => queryFunction(pageOn), {
    cacheTime: 0,
    initialData: getEmptyApiList(),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    history.push(HOME_ROUTE_PATH);
  }

  const handleApiError = (error): void => {
    if (Array.isArray(error)) {
      dispatch({ type: ActionType.SET_MUTATE_ERRORS, mutateErrors: error });
    }
  };

  const clearApiError = (): void => dispatch({ type: ActionType.SET_MUTATE_ERRORS_INITIAL });

  const successApiResponse = (): void => {
    dispatch({ type: ActionType.SET_DIALOG_CLOSED });
    refetch();
  };

  // eslint-disable-next-line
  const mutationOptions: UseMutationOptions<T, any, T, void> = {
    onError: handleApiError,
    onMutate: clearApiError,
    onSuccess: successApiResponse,
  };

  const { mutateAsync, isLoading: isMutating } = useMutation(createMutationFunction, mutationOptions);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useMutation(deleteMutationFunction, mutationOptions);
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useMutation(updateMutationFunction, mutationOptions);

  const onDialogClose = (): void => dispatch({ type: ActionType.SET_DIALOG_CLOSED });

  // eslint-disable-next-line
  const onCreate = (event: MouseEvent<HTMLButtonElement>): void => {
    dispatch({ type: ActionType.SET_MUTATE_DATA, mutateData: emptyModel });
  };

  // eslint-disable-next-line
  const onDelete = (event, rowData: T | Array<T>): void => {
    dispatch({ type: ActionType.SET_MUTATE_ERRORS_INITIAL });
    /**
     * if multiple rows selection is enabled in `material-table`,
     * it will return an array of selected elements
     */
    if (!Array.isArray(rowData)) {
      dispatch({ type: ActionType.SET_MUTATE_DELETE_DATA, mutateData: rowData });
    }
  };

  // eslint-disable-next-line
  const onUpdate = (event, rowData: T | Array<T>): void => {
    dispatch({ type: ActionType.SET_MUTATE_ERRORS_INITIAL });
    /**
     * if multiple rows selection is enabled in `material-table`,
     * it will return an array of selected elements
     */
    if (!Array.isArray(rowData)) {
      dispatch({ type: ActionType.SET_MUTATE_DATA, mutateData: rowData });
    }
  };

  const onPageChange = (selectedItem: { selected: number; }): void => {
    dispatch({ type: ActionType.SET_ON_PAGE, pageOn: selectedItem.selected });
  };

  const onDeleteSuccess = async (deleteResponse: T): Promise<void> => {
    await deleteAsync(deleteResponse);
  };

  const onSuccess = async (mutatedResponse: T): Promise<void> => {
    // because inconsistent naming on the BE side
    if ('companyId' in mutatedResponse || 'id' in mutatedResponse) {
      /**
       * `material-table` modifies its own props (which is a very ugly anti-pattern) to track row selection
       */
      // eslint-disable-next-line
      delete (mutatedResponse as any).tableData;
      await updateAsync(mutatedResponse);
    } else {
      await mutateAsync(mutatedResponse);
    }
  };

  return {
    apiList,
    isFetching,
    isMutating: isMutating || isUpdating || isDeleting,
    mutateErrors,
    mutateData,
    openConfirmationDialog,
    openDialog,
    pageOn,
    onCreate,
    onDelete,
    onDialogClose,
    onPageChange,
    onDeleteSuccess,
    onSuccess,
    onUpdate,
    refetch,
  };
};
