import api, { handleApiError } from '../apiService';

import { AdminUser, ApiList, UserRole } from '../../models';

import { PAGINATION_TABLE_SIZE } from '../../configs/environments';

const adminUserRemote = {
  getAdminUsers: async (page: number): Promise<ApiList<AdminUser>> => {
    try {
      const { data } = await api.get(`adminuser/getAll?size=${PAGINATION_TABLE_SIZE}&page=${page}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  createAdminUser: async (newAdminUser: AdminUser): Promise<AdminUser> => {
    try {
      const { data } = await api.post('adminuser/add', {
        ...newAdminUser,
        roles: (newAdminUser.roles as Array<UserRole>)[0].name, // BE is still inconsistent about this
      });
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  updateAdminUser: async (adminUser: AdminUser): Promise<AdminUser> => {
    try {
      const { data } = await api.put(`adminuser/update/${adminUser.id}`, {
        ...adminUser,
        roles: (adminUser.roles as Array<UserRole>)[0].name, // BE is still inconsistent about this
      });
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default adminUserRemote;
