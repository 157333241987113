import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import UserContext, { UserContextModel } from '../contexts/UserContext';

import { LOGIN_ROUTE_PATH } from './routesPath';

interface PrivateRouteProps {
  // eslint-disable-next-line
  component: any;
  path?: string;
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const { component: Component, exact, path } = props;
  const { user } = useContext<UserContextModel>(UserContext);
  const isAuthenticated: boolean = user !== null;

  const privateRouteContent = (renderProps: RouteComponentProps<{ [x: string]: string | undefined; }>) => {
    if (!isAuthenticated) {
      return (
        <Redirect to={{ pathname: LOGIN_ROUTE_PATH, state: { from: renderProps.location } }} />
      );
    }

    return (
      <Component
        history={renderProps.history}
        location={renderProps.location}
        match={renderProps.match}
      />
    );
  };

  return (
    <Route exact={exact} path={path} render={privateRouteContent} />
  );
};

export default PrivateRoute;
