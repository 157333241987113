import { Column } from 'material-table';
import { TFunction } from 'i18next';

import { PortalUser } from '../../models';

import activeColumnRenderer from '../../utils/activeColumnRenderer';
import measurementSystemDesc from '../../utils/measurementSystem';

const columns = (translation: TFunction): Array<Column<PortalUser>> => [
  {
    title: translation('table.columns.portalUser.userName'),
    field: 'userName',
    cellStyle: { minWidth: 200 },
  },
  {
    title: translation('table.columns.portalUser.email'),
    field: 'email',
    cellStyle: { minWidth: 250 },
  },
  {
    title: translation('table.columns.portalUser.currency'),
    field: 'currency.name',
    cellStyle: { minWidth: 50 },
  },
  {
    title: translation('table.columns.portalUser.measurementSystem'),
    field: 'unit.name',
    cellStyle: { minWidth: 150 },
    render: ({ unit }) => {
      if (unit) {
        // eslint-disable-next-line
        return translation(`measurementSystemText.${measurementSystemDesc[(unit as { id: number; name: string; }).name]}`); // BE is still inconsistent about this
      }

      return null;
    },
  },
  {
    title: translation('table.columns.portalUser.status'),
    field: 'active',
    cellStyle: { minWidth: 50 },
    render: ({ active }) => activeColumnRenderer(active, translation),
  },
];

export default columns;
