import UserRole from './UserRole';
import UserRoleType from './UserRoleType.enum';

export default interface AdminUser {
  active: boolean;
  email: string;
  id?: number;
  password?: string;
  roles: Array<UserRole> | string; // BE is still inconsistent about this
  userName: string;
  /**
   * `material-table` modifies its own props (which is a very ugly anti-pattern) to track row selection
   */
  tableData?: { id: number };
}

export function getEmptyAdminUser(): AdminUser {
  return {
    active: true,
    email: '',
    password: '',
    roles: [{ // no BE defined yet
      id: 1,
      name: UserRoleType.ADMIN,
    }],
    userName: '',
  };
}
