import React, { FC } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Box, Typography } from '..';

const useStyles = makeStyles(() => createStyles({
  wrapper: {
    marginBottom: 10,
  },
  typography: {
    color: '#fff',
    textAlign: 'center',
    letterSpacing: '0.5rem',
    textShadow: '3px 3px 3px #000',
  },
}));

// eslint-disable-next-line
const PageHeader: FC<unknown> = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h4" component="h4" className={classes.typography}>
        {children}
      </Typography>
    </Box>
  );
};

export default PageHeader;
