import React from 'react';
import { useTranslation } from 'react-i18next';

import { Settings as SettingsIcon, VisibilitySharp as VisibilityOnIcon, VisibilityOffSharp as VisibilityOffIcon } from '@material-ui/icons';
import {
  Box, Button, Card, CardContent, FormHelperText, IconButton, TextField,
} from '../common';
import ProgressIcon from '../common/progressIcon/ProgressIcon';

import { useLogic } from './hooks/useLogic';
import { usePasswordToggle } from '../../hooks/usePasswordToggle';

import useStyles from './loginContainerStyles';

const LoginContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    errorMessage, isLoading, password, username, onInputChange, onSubmit,
  } = useLogic();
  const {
    isPasswordType, passwordInputRef, toggleInputType, onToggleVisibility,
  } = usePasswordToggle();
  
  return (
    <Box className={classes.loginContent}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={onSubmit}>
            <Box className={classes.headerContainer}>
              <Box fontSize="h5.fontSize" className={classes.loginHeader}>{t('signInTitle')}</Box>
              <SettingsIcon fontSize="large" />
            </Box>
            <TextField
              required
              disabled={isLoading}
              fullWidth
              variant="outlined"
              label={t('username')}
              margin="normal"
              value={username}
              name="username"
              onChange={onInputChange}
              classes={{ root: classes.textField }}
            />
            <Box display="flex" alignItems="center" width="100%">
              <TextField
                inputRef={passwordInputRef}
                required
                disabled={isLoading}
                fullWidth
                variant="outlined"
                type={toggleInputType}
                label={t('password')}
                margin="normal"
                value={password}
                name="password"
                onChange={onInputChange}
                classes={{ root: classes.textField }}
              />
              <IconButton
                aria-label="toggleVisibility"
                data-item="toggleVisibility"
                color="default"
                className={isPasswordType ? classes.visibilityIconButton : classes.visibilityIconButtonClicked}
                onClick={onToggleVisibility}
              >
                { isPasswordType ? (
                  <VisibilityOffIcon className={classes.visibilityIcon} />
                ) : (
                  <VisibilityOnIcon className={classes.visibilityIcon} />
                ) }
              </IconButton>
            </Box>
            <Box minHeight="20px">
              { errorMessage && (
                <FormHelperText classes={{ root: classes.helperText }} error={true}>
                  {errorMessage}
                </FormHelperText>
              ) }
            </Box>
            <Box className={classes.submitBtnContent}>
              <Button
                disabled={isLoading}
                variant="contained"
                color="default"
                type="submit"
              >
                { isLoading ? <ProgressIcon isFetching={isLoading} /> : t('signIn')}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginContainer;
