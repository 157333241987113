import React from 'react';

import {
  AccountBox as AccountBoxIcon,
  Assignment as AssignmentIcon,
  Domain as DomainIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Web as WebIcon,
} from '@material-ui/icons';

import { MenuItem } from '../models';

import {
  PROVIDER_MARKUP, PROVIDER_SUPPLIER_PATH, USERS_API_PATH, USERS_PATH, USERS_PORTAL_PATH,
} from '../routes/routesPath';

export const NO_LINK = '#';
const menuItems: Array<MenuItem> = [
  {
    label: 'menu.items.userManagement',
    icon: <GroupIcon />,
    link: NO_LINK,
    children: [
      {
        label: 'menu.items.apiUsers',
        icon: <AccountBoxIcon />,
        link: USERS_API_PATH,
      },
      {
        label: 'menu.items.portalUsers',
        icon: <WebIcon />,
        link: USERS_PORTAL_PATH,
      },
      {
        label: 'menu.items.adminPortalUsers',
        icon: <DomainIcon />,
        link: USERS_PATH,
      },
    ],
  },
  {
    label: 'menu.items.providerSupplierConfig',
    icon: <AssignmentIcon />,
    link: PROVIDER_SUPPLIER_PATH,
  },
  {
    label: 'menu.items.providerMarkupRoutines',
    icon: <TrendingUpIcon />,
    link: PROVIDER_MARKUP,
  },
];

export default menuItems;
