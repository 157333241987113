import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ShortUniqueId from 'short-unique-id';

import { FilterNone as CopyClipboardIcon } from '@material-ui/icons';

import {
  Box, FormControl, FormControlLabel, IconButton, InputLabel, Select, Switch, TextField, Tooltip,
} from '../../../common';

import CommonDialogButtons from '../../../common/commonDialogButtons/CommonDialogButtons';
import CommonDialogError from '../../../common/commonDialogError/CommonDialogError';
import CommonTableDialog from '../../../common/commonTableDialog/CommonTableDialog';

import { ApiUser } from '../../../../models';

import { useCommonInputLogic } from '../../../../hooks/useCommonInputLogic';
import { useLogic } from './hooks/useLogic';

import titles from '../../../../configs/titles';
import currencyMock from '../../../../utils/currencyMock';
import useStyles from '../../../commonDialogInputStyles/commonDialogInputStyles';

const CURRENCY_LIST_MOCK = currencyMock;
const uid: ShortUniqueId = new ShortUniqueId({ length: 18 });

interface AddEditApiUserDialogProps {
  countries: { [key: string]: string; };
  errors: Array<string> | null;
  isMutating: boolean;
  isOpen: boolean;
  userData: ApiUser;
  onClose: () => void;
  onSuccess: (apiUser: ApiUser) => void;
}

const AddEditApiUserDialog: FC<AddEditApiUserDialogProps> = (props: AddEditApiUserDialogProps) => {
  const {
    countries, errors, isMutating, isOpen, userData, onClose, onSuccess,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutated, onChange, onSubmit } = useCommonInputLogic<ApiUser>(
    {
      ...userData,
      apiKey: userData.companyId ? userData.apiKey : uid(),
      apiSecret: userData.companyId ? userData.apiSecret : uid(),
    },
    onSuccess,
  );
  const {
    apiKeyRef, apiSecretRef, countryCodeEntries, iconButtonRef, onCopyToClipboard,
  } = useLogic(countries);

  return (
    <CommonTableDialog dialogTitle={`dialogs.apiUser.${!mutated.companyId ? 'createTitle' : 'updateTitle'}`} isDialogOpen={isOpen} onClose={onClose}>
      <Box mb="16px">
        { errors && <CommonDialogError errors={errors} /> }
      </Box>
      <form onSubmit={onSubmit}>
        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="companyName"
              onChange={onChange}
              label={t('table.columns.apiUser.companyName')}
              value={mutated.companyName}
            />
          </Box>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="distributor-contact-title">{t('table.columns.apiUser.distributorContactTitle')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'distributor-contact-title',
                  name: 'distributorContactTitle',
                  'data-item': 'distributorContactTitle',
                }}
                onChange={onChange}
                value={mutated.distributorContactTitle}
                label={t('table.columns.apiUser.distributorContactTitle')}
              >
                <option aria-label="None" value="" />
                { titles.map(
                  (el) => <option key={el.value} value={el.value}>{t(el.label)}</option>,
                ) }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              inputProps={{ type: 'email' }}
              name="companyEmail"
              onChange={onChange}
              label={t('table.columns.apiUser.companyEmail')}
              value={mutated.companyEmail}
            />
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="distributorContactName"
              onChange={onChange}
              label={t('table.columns.apiUser.distributorContactName')}
              value={mutated.distributorContactName}
            />
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="companyPhone"
              onChange={onChange}
              label={t('table.columns.apiUser.companyPhone')}
              value={mutated.companyPhone}
            />
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="iataNumber"
              onChange={onChange}
              label={t('table.columns.apiUser.iataNumber')}
              value={mutated.iataNumber}
            />
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="currency-code">{t('table.columns.apiUser.currencyCode')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'currency-code',
                  name: 'currencyCode',
                  'data-item': 'currencyCode',
                }}
                onChange={onChange}
                label={t('table.columns.apiUser.currencyCode')}
                value={mutated.currencyCode}
              >
                <option aria-label="None" value="" />
                { CURRENCY_LIST_MOCK.map(
                  (el) => <option key={el.id} value={el.name}>{el.name}</option>,
                ) }
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="atolNumber"
              onChange={onChange}
              label={t('table.columns.apiUser.atolNumber')}
              value={mutated.atolNumber}
            />
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <FormControl required fullWidth variant="outlined" margin="dense" className={classes.textfield}>
              <InputLabel htmlFor="country-code">{t('table.columns.apiUser.countryCode')}</InputLabel>
              <Select
                native
                inputProps={{
                  id: 'country-code',
                  name: 'countryCode',
                  'data-item': 'countryCode',
                }}
                onChange={onChange}
                label={t('table.columns.apiUser.countryCode')}
                value={mutated.countryCode}
              >
                <option aria-label="None" value="" />
                { countryCodeEntries.map((entry) => (
                  <option key={entry[0]} value={entry[0]}>{entry[1]}</option>
                )) }
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.rowItem}>
            <Box display="flex" alignItems="center" width="100%">
              <TextField
                inputRef={apiKeyRef}
                fullWidth
                variant="outlined"
                margin="dense"
                autoComplete="off"
                type="text"
                classes={{ root: classes.textfield }}
                name="apiKey"
                label={t('table.columns.apiUser.apiKey')}
                value={mutated.apiKey}
              />
              <IconButton
                aria-label="copyToClipboard"
                data-item="copyApiKey"
                ref={iconButtonRef}
                color="default"
                className={classes.copyIconButton}
                onClick={onCopyToClipboard}
              >
                <Tooltip title={t('copyToClipboard')}>
                  <CopyClipboardIcon className={classes.copyIcon} />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box className={classes.rowItem}>
            <TextField
              required
              fullWidth
              variant="outlined"
              multiline
              rows={5}
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="bankDetails"
              onChange={onChange}
              label={t('table.columns.apiUser.bankDetails')}
              value={mutated.bankDetails}
            />
          </Box>
          <Box className={classes.rowItem}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box display="flex" alignItems="center" width="100%">
                <TextField
                  inputRef={apiSecretRef}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  autoComplete="off"
                  type="text"
                  classes={{ root: classes.textfield }}
                  name="apiSecret"
                  label={t('table.columns.apiUser.apiSecret')}
                  value={mutated.apiSecret}
                />
                <IconButton
                  aria-label="copyToClipboard"
                  data-item="copyApiSecret"
                  ref={iconButtonRef}
                  color="default"
                  className={classes.copyIconButton}
                  onClick={onCopyToClipboard}
                >
                  <Tooltip title={t('copyToClipboard')}>
                    <CopyClipboardIcon className={classes.copyIcon} />
                  </Tooltip>
                </IconButton>
              </Box>
              <Box mt="20px">
                <FormControlLabel
                  control={<Switch name="isActive" color="primary" checked={mutated.isActive} onChange={onChange} />}
                  label={t('table.columns.apiUser.isActive')}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.rowContainer}>
          <Box width="100%">
            <TextField
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              margin="dense"
              autoComplete="off"
              type="text"
              classes={{ root: classes.textfield }}
              name="additionalInfo"
              onChange={onChange}
              label={t('table.columns.apiUser.additionalInfo')}
              value={mutated.additionalInfo}
            />
          </Box>
        </Box>

        <CommonDialogButtons
          actionButtonTitle={!mutated.companyId ? 'createBtnTitle' : 'updateBtnTitle'}
          isMutating={isMutating}
          onClose={onClose}
        />

      </form>
    </CommonTableDialog>
  );
};

export default AddEditApiUserDialog;
