import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable, { Column, Options } from 'material-table';

import { useLogic } from './hooks/useLogic';

// eslint-disable-next-line
interface TableProps<RowData extends object> {
  columns: Array<Column<RowData>>;
  data: Array<RowData>;
  isLoading?: boolean;
  options?: Options<RowData>;
  onDeleteAction?: (event, data: RowData | Array<RowData>) => void;
  onEditAction?: (event, data: RowData | Array<RowData>) => void;
}

// eslint-disable-next-line
const Table = <RowData extends object>(props: TableProps<RowData>) => {
  const {
    columns, data, isLoading, options, onDeleteAction, onEditAction,
  } = props;
  const { t } = useTranslation();

  const { actions, localization } = useLogic(t, onDeleteAction, onEditAction);

  return (
    <MaterialTable
      actions={actions}
      columns={columns}
      data={data}
      isLoading={isLoading}
      localization={localization}
      options={options}
    />
  );
};

export default Table;
