import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Add as AddIcon } from '@material-ui/icons';

import { Box, Card, CardContent } from '..';

import PageHeader from '../pageHeader/PageHeader';
import RrButton from '../rrButton/RrButton';

import useStyles from './commonTableContainerStyles';

interface CommonTableContainerProps {
  addEditDialog?: JSX.Element;
  confirmationDialog?: JSX.Element;
  headerTitle: string;
  isConfirmationOpen?: boolean;
  isDialogOpen?: boolean;
  pagination?: JSX.Element;
  table: JSX.Element;
  onAddButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CommonTableContainer: FC<CommonTableContainerProps> = (props: CommonTableContainerProps) => {
  const {
    addEditDialog, confirmationDialog, headerTitle, isConfirmationOpen, isDialogOpen, pagination, table, onAddButtonClick,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.commonUserContainer}>
        <Box className={classes.headerContainer}>
          <Box className={classes.buttonContainer}>
            { addEditDialog && (
              <RrButton
                title={t('addBtnTitle')}
                startIcon={<AddIcon />}
                onButtonClicked={onAddButtonClick}
              />
            ) }
          </Box>
          <Box className={classes.titleContainer}>
            <PageHeader>{ t(headerTitle) }</PageHeader>
          </Box>
        </Box>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            { table }
            { pagination }
          </CardContent>
        </Card>
      </Box>
      { isDialogOpen && addEditDialog }
      { isConfirmationOpen && confirmationDialog }
    </>
  );
};

export default CommonTableContainer;
