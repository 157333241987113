import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Button } from '..';

import ProgressIcon from '../progressIcon/ProgressIcon';

const useStyles = makeStyles(() => createStyles({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    '& Button': {
      fontWeight: 'bold',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '30%',
  },
}));

interface CommonDialogButtonsProps {
  actionButtonTitle: string;
  isMutating: boolean;
  onClose: () => void;
}

const CommonDialogButtons: FC<CommonDialogButtonsProps> = (props: CommonDialogButtonsProps) => {
  const { actionButtonTitle, isMutating, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.buttonsContainer}>
      <Box className={classes.buttons}>
        <Button disabled={isMutating} variant="contained" color="default" type="submit">
          { isMutating ? <ProgressIcon isFetching={isMutating} /> : t(actionButtonTitle) }
        </Button>
        <Button disabled={isMutating} variant="contained" color="primary" onClick={onClose}>
          {t('cancelBtnTitle')}
        </Button>
      </Box>
    </Box>
  );
};

export default CommonDialogButtons;
