import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  loginContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000a',
    height: '100vh',
  },
  card: {
    minWidth: 450,
    margin: '8px 0',
    boxShadow: '2px 2px 30px 5px #9d9d9d',
  },
  cardContent: {
    paddingTop: 24,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginHeader: {
    marginBottom: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000',
    },
  },
  textFieldTerms: {
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiInputBase-inputMultiline': {
      color: '#000',
      fontSize: 14,
    },
  },
  helperText: {
    marginTop: 0,
  },
  submitBtnContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 10,
    '& Button': {
      fontWeight: 'bold',
    },
  },
  visibilityIconButton: {
    padding: '8px 0 0 8px',
    '&:hover': {
      color: '#000',
      backgroundColor: 'transparent',
    },
  },
  visibilityIconButtonClicked: {
    padding: '8px 0 0 8px',
    color: '#000',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'transparent',
    },
  },
  visibilityIcon: {
    fontSize: 35,
  },
}));
