import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    color: '#fff',
    backgroundColor: '#555555',
    '& .MuiListItem-root ': {
      paddingTop: 3,
      paddingBottom: 1,
      marginBottom: 4,
    },
    '& .MuiListItemIcon-root ': {
      color: '#fff',
      minWidth: 40,
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      boxShadow: '1px 4px 10px 5px #fffc',
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.24)',
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
    },
    '& .MuiListItem-root > a:hover': {
      backgroundColor: 'transparent',
    },
  },
  notNested: {
    paddingLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));
