import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { adminUserRemote } from '../../../api/services';

import AddEditAdminUserDialog from './addEditDialog/AddEditAdminUserDialog';
import CommonTableContainer from '../../common/commonTableContainer/CommonTableContainer';
import Pagination from '../../common/pagination/Pagination';
import Table from '../../table/Table';

import AdminUser, { getEmptyAdminUser } from '../../../models/AdminUser';

import { useCommonTableLogic, UseCommonContainerLogicParams } from '../../../hooks/useCommonTableLogic';

import columns from '../../../configs/tablesColumns/adminUserColumns';
import commonTableOptions from '../../../configs/tableOptions';

const useLogicParams: UseCommonContainerLogicParams<AdminUser> = {
  emptyModel: getEmptyAdminUser(),
  queryKey: 'adminUsers',
  createMutationFunction: adminUserRemote.createAdminUser,
  queryFunction: adminUserRemote.getAdminUsers,
  updateMutationFunction: adminUserRemote.updateAdminUser,
};

const AdminUserContainer: FC<unknown> = () => {
  const { t } = useTranslation();
  const {
    apiList, isFetching, isMutating, mutateErrors, mutateData, openDialog, pageOn,
    onCreate, onDialogClose, onPageChange, onSuccess, onUpdate,
  } = useCommonTableLogic<AdminUser>(useLogicParams);

  return (
    <CommonTableContainer
      addEditDialog={(
        <AddEditAdminUserDialog
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openDialog}
          userData={mutateData}
          onClose={onDialogClose}
          onSuccess={onSuccess}
        />
      )}
      headerTitle="userManagement.header.adminUsers"
      isDialogOpen={openDialog}
      pagination={(
        <Pagination
          forcePage={pageOn}
          paginatedPage={apiList.number}
          totalElements={apiList.totalElements}
          totalPages={apiList.totalPages}
          onPageChange={onPageChange}
        />
      )}
      table={(
        <Table
          columns={columns(t)}
          data={apiList.content}
          isLoading={isFetching}
          options={commonTableOptions}
          onEditAction={onUpdate}
        />
      )}
      onAddButtonClick={onCreate}
    />
  );
};

export default AdminUserContainer;
