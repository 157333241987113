import api, { handleApiError } from '../apiService';

import ApiList, { getEmptyApiList } from '../../models/ApiList';
import { Markup } from '../../models';

const markupRemote = {
  getMarkups: async (): Promise<ApiList<Markup>> => {
    try {
      const mockApiList: ApiList<Markup> = getEmptyApiList();
      const { data } = await api.get('api/markup-params');

      /**
       * This end-point doesn't return response in ApiList (paginated),
       * so I need to wrap it to work with useCommonTableLogic hook
       */
      return {
        ...mockApiList,
        content: data,
      };
    } catch (error) {
      return handleApiError(error);
    }
  },
  createMarkup: async (newMarkup: Markup): Promise<Markup> => {
    try {
      const { data } = await api.post('api/markup-params', newMarkup);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  deleteMarkup: async (deleteMarkup: Markup): Promise<Markup> => {
    try {
      const { data } = await api.delete(`api/markup-params/${deleteMarkup.id}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default markupRemote;
