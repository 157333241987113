import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { markupRemote } from '../../api/services';

import AddMarkupRoutineDialog from './addDialog/AddMarkupRoutineDialog';
import CommonTableContainer from '../common/commonTableContainer/CommonTableContainer';
import DeleteConfirmationDialog from '../common/deleteConfirmationDialog/DeleteConfirmationDialog';
import Table from '../table/Table';

import { Markup } from '../../models';

import { useCommonTableLogic, UseCommonContainerLogicParams } from '../../hooks/useCommonTableLogic';
import { useProviderMapping } from '../../hooks/useProviderMapping';

import columns from '../../configs/tablesColumns/providerMarkupColumns';
import commonTableOptions from '../../configs/tableOptions';

const useLogicParams: UseCommonContainerLogicParams<Markup> = {
  emptyModel: {} as Markup,
  queryKey: 'providerMarkup',
  createMutationFunction: markupRemote.createMarkup,
  deleteMutationFunction: markupRemote.deleteMarkup,
  queryFunction: markupRemote.getMarkups,
};

const MarkupRoutinesContainer: FC<unknown> = () => {
  const { t } = useTranslation();

  const {
    apiList, isFetching, isMutating, mutateErrors, mutateData, openConfirmationDialog, openDialog,
    onCreate, onDelete, onDialogClose, onDeleteSuccess, onSuccess,
  } = useCommonTableLogic<Markup>(useLogicParams);

  const { providerMapping } = useProviderMapping();

  return (
    <CommonTableContainer
      addEditDialog={(
        <AddMarkupRoutineDialog
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openDialog}
          data={mutateData}
          providers={providerMapping}
          onClose={onDialogClose}
          onSuccess={onSuccess}
        />
      )}
      confirmationDialog={(
        <DeleteConfirmationDialog
          entity={mutateData}
          errors={mutateErrors}
          isMutating={isMutating}
          isOpen={openConfirmationDialog}
          title="dialogs.providerMarkup.deleteConfirmation"
          onClose={onDialogClose}
          onSuccess={onDeleteSuccess}
        >
          { mutateData ? (
            <>
              { t('dialogs.providerMarkup.deleteConfirmationText', {
                markup: mutateData.markup_value,
                supplier: providerMapping ? providerMapping[mutateData.provider_id] : mutateData.provider_id,
              }) }
            </>
          ) : null }
        </DeleteConfirmationDialog>
      )}
      headerTitle="providerMarkup.header.providerMarkup"
      isDialogOpen={openDialog}
      isConfirmationOpen={openConfirmationDialog}
      table={(
        <Table
          columns={columns(t, providerMapping)}
          data={apiList.content}
          isLoading={isFetching}
          options={commonTableOptions}
          onDeleteAction={onDelete}
        />
      )}
      onAddButtonClick={onCreate}
    />
  );
};

export default MarkupRoutinesContainer;
