import {
  MouseEvent, MutableRefObject, useEffect, useRef, useState,
} from 'react';

export const useLogic = (countries: { [key: string]: string; }): {
  apiKeyRef: MutableRefObject<HTMLInputElement>;
  apiSecretRef: MutableRefObject<HTMLInputElement>;
  countryCodeEntries: Array<[string, string]>;
  iconButtonRef: MutableRefObject<HTMLButtonElement>;
  onCopyToClipboard: (event: MouseEvent<HTMLButtonElement>) => void;
} => {
  const apiKeyRef: MutableRefObject<HTMLInputElement> = useRef<HTMLInputElement>();
  const apiSecretRef: MutableRefObject<HTMLInputElement> = useRef<HTMLInputElement>();
  const iconButtonRef: MutableRefObject<HTMLButtonElement> = useRef<HTMLButtonElement>();
  
  const [countryCodeEntries, setCountryCodeEntries] = useState<Array<[string, string]>>([]);

  useEffect(() => {
    setCountryCodeEntries(Object.entries(countries));
  // eslint-disable-next-line
  }, []);

  const onCopyToClipboard = (event: MouseEvent<HTMLButtonElement>): void => {
    const { item } = event.currentTarget.dataset;

    if (item === 'copyApiKey') {
      apiKeyRef.current.select();
      apiKeyRef.current.setSelectionRange(0, 99999); // For mobile devices
      apiKeyRef.current.style.color = '#6d6d6d';
    } else {
      apiSecretRef.current.select();
      apiSecretRef.current.setSelectionRange(0, 99999);
      apiSecretRef.current.style.color = '#6d6d6d';
    }
  
    document.execCommand('copy');
    iconButtonRef.current.focus(); // prefer to not show input selected
  };

  return {
    apiKeyRef,
    apiSecretRef,
    countryCodeEntries,
    iconButtonRef,
    onCopyToClipboard,
  };
};
