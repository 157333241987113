import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  dialog: {
    '& .MuiDialogContent-root': {
      padding: '0 24px 24px 24px',
    },
    '& .MuiDialog-paper': {
      minWidth: 750,
      alignItems: 'center',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  dialogTitleContainer: {
    display: 'flex',
    width: '100%',
  },
  dialogTitle: {
    width: '100%',
    textAlign: 'center',
    marginRight: -50,
  },
  dialogContent: {
    width: '100%',
    textAlign: 'center',
  },
  iconContainer: {
    color: '#000',
    '&:hover': {
      background: 'none',
    },
  },
}));
