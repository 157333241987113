import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AccountCircle as AccountCircleIcon, ExitToApp as ExitToAppIcon } from '@material-ui/icons';
import {
  Box, IconButton, Menu, MenuItem,
} from '../common';

import { useLogic } from './hooks/index';

const useStyles = makeStyles(() => createStyles({
  profileMenu: {
    '& .MuiPaper-root': {
      top: '55px !important',
      backgroundColor: '#000b',
      color: '#fff',
    },
  },
}));

const ProfileMenu: FC<unknown> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    anchorEl, isOpen, onHandleClose, onProfileIconClick, onSignOutButtonClicked,
  } = useLogic();

  return (
    <>
      <IconButton
        aria-label="user profile menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onProfileIconClick}
        color="primary"
        size="medium"
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        className={classes.profileMenu}
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        transitionDuration={300}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={onHandleClose}
      >
        <MenuItem onClick={onSignOutButtonClicked}>
          <ExitToAppIcon />
          <Box ml="10px">{t('signOut')}</Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
