import api, { handleApiError } from '../apiService';

import { ApiList, ProviderSupplierPair } from '../../models';

import { PAGINATION_TABLE_SIZE } from '../../configs/environments';

const blacklistRemote = {
  getBlacklistPairs: async (page: number): Promise<ApiList<ProviderSupplierPair>> => {
    try {
      const { data } = await api.get(`api/blacklist?size=${PAGINATION_TABLE_SIZE}&page=${page}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  createBlacklistPair: async (newPair: ProviderSupplierPair): Promise<ProviderSupplierPair> => {
    try {
      const { data } = await api.post('api/blacklist', newPair);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
  deleteBlacklistPair: async (deletePair: ProviderSupplierPair): Promise<ProviderSupplierPair> => {
    try {
      const { data } = await api.delete(`api/blacklist/${deletePair.providerId}/${deletePair.apiClientId}`);
      return data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};

export default blacklistRemote;
