import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import { Box } from '..';

import { PAGINATION_TABLE_SIZE } from '../../../configs/environments';
import useStyles from './paginationStyles';

export const getShowFrom = (paginatedPage: number, pageSize: number): number => (
  (paginatedPage * pageSize) + 1
);

export const getShowTo = (
  paginatedPage: number, pageSize: number, totalPages: number, totalElements: number,
): number => (
  (paginatedPage + 1) < totalPages ? ((paginatedPage + 1) * pageSize) : totalElements
);

interface PaginationProps {
  /**
   * To override selected page with parent prop.
   */
  forcePage?: number;
  paginatedPage: number;
  totalElements: number;
  totalPages: number;
  onPageChange: (selectedItem: { selected: number; }) => void;
}

const Pagination: FC<PaginationProps> = (props: PaginationProps) => {
  const {
    forcePage,
    paginatedPage,
    totalElements,
    totalPages,
    onPageChange,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const showFrom: number = getShowFrom(paginatedPage, PAGINATION_TABLE_SIZE);
  const showTo: number = getShowTo(paginatedPage, PAGINATION_TABLE_SIZE, totalPages, totalElements);

  try {
    return (
      <Box className={classes.content}>
        <Box className={classes.itemText}>{`${t('paginationItems')}: ${totalElements}`}</Box>
        { totalElements > 0 && (
          <>
            <Box className={classes.paginationContent}>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                forcePage={forcePage}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                disabledClassName={'disable'}
              />
            </Box>
            <Box className={classes.legendText}>{`${t('paginationShowing')} ${showFrom} - ${showTo}`}</Box>
          </>
        ) }
      </Box>
    );
  } catch {
    return null;
  }
};

const MemoizedPagination = React.memo(Pagination);
export default MemoizedPagination;
