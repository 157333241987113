import { MouseEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, UseQueryOptions } from 'react-query';

import UserContext, { UserContextModel } from '../../../contexts/UserContext';

import { userRemote } from '../../../api/services';

import { LOGIN_ROUTE_PATH } from '../../../routes/routesPath';

export const useLogic = (): {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  onHandleClose: () => void;
  onProfileIconClick: (event: MouseEvent<HTMLElement>) => void;
  onSignOutButtonClicked: () => void;
} => {
  const history = useHistory();
  const { setUser } = useContext<UserContextModel>(UserContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const successApiResponse = (): void => {
    setUser(null);
    history.push(LOGIN_ROUTE_PATH);
  };

  const queryOptions: UseQueryOptions = {
    enabled: false,
    onSuccess: successApiResponse,
  };

  const { refetch } = useQuery('signOut', userRemote.signOut, queryOptions);

  const onProfileIconClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onHandleClose = (): void => setAnchorEl(null);

  const onSignOutButtonClicked = (): void => {
    refetch();
  };

  return {
    anchorEl,
    isOpen,
    onHandleClose,
    onProfileIconClick,
    onSignOutButtonClicked,
  };
};
