import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear as CloseIcon } from '@material-ui/icons';

import {
  Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip,
} from '..';

import useStyles from './commonTableDialogStyles';

interface CommonTableDialogProps {
  dialogTitle: string;
  isDialogOpen: boolean;
  onClose: () => void;
  children?: JSX.Element | Array<JSX.Element>;
}

const CommonTableDialog: FC<CommonTableDialogProps> = (props: CommonTableDialogProps) => {
  const {
    dialogTitle, isDialogOpen, onClose, children,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      className={classes.dialog}
    >
      <Box className={classes.dialogTitleContainer}>
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          { t(dialogTitle) }
        </DialogTitle>
        <Box display="flex">
          <IconButton aria-label="Close" classes={{ root: classes.iconContainer }} onClick={onClose}>
            <Tooltip title={t('close')}>
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <DialogContent className={classes.dialogContent}>
        { children }
      </DialogContent>
    </Dialog>
  );
};

export default CommonTableDialog;
