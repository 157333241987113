import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box } from '../common';

import AdminUserContainer from '../userManagement/adminUser/AdminUserContainer';
import ApiUserContainer from '../userManagement/apiUser/ApiUserContainer';
import AppBarTop from '../appBarTop/AppBarTop';
import DashboardContainer from '../dashboard/DashboardContainer';
import MarkupRoutinesContainer from '../providerMarkupRoutines/MarkupRoutinesContainer';
import MenuContainer from '../menu/MenuContainer';
import PortalUserContainer from '../userManagement/portalUser/PortalUserContainer';
import ProviderSupplierConfContainer from '../providerSupplierConf/ProviderSupplierConfContainer';

import {
  HOME_ROUTE_PATH, PROVIDER_MARKUP, PROVIDER_SUPPLIER_PATH,
  USERS_API_PATH, USERS_PATH, USERS_PORTAL_PATH,
} from '../../routes/routesPath';

const appBarHeight = 65;
const menuWidth = 270;
const useStyles = makeStyles(() => createStyles({
  root: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    marginTop: appBarHeight,
  },
  menuContainer: {
    width: menuWidth,
    position: 'fixed',
  },
  sectionContent: {
    width: `calc(99vw - ${menuWidth}px)`,
    height: `calc(100vh - ${appBarHeight}px)`,
    backgroundColor: '#555555',
    position: 'relative',
    left: menuWidth + 13,
  },
}));

const HomeContainer: FC<unknown> = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <AppBarTop />
      <Box className={classes.content}>
        <Box className={classes.menuContainer}>
          <MenuContainer />
        </Box>
        <Box className={classes.sectionContent}>
          <Switch>
            <Route path={USERS_API_PATH} component={ApiUserContainer} />
            <Route path={USERS_PORTAL_PATH} component={PortalUserContainer} />
            <Route path={USERS_PATH} component={AdminUserContainer} />
            <Route path={PROVIDER_SUPPLIER_PATH} component={ProviderSupplierConfContainer} />
            <Route path={PROVIDER_MARKUP} component={MarkupRoutinesContainer} />
            <Route path={HOME_ROUTE_PATH} component={DashboardContainer} />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeContainer;
