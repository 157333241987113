import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  card: {
    width: 250,
    height: 250,
    boxShadow: '0 0 30px 5px #fffb',
    margin: '8px 0',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fff',
      border: '2px solid #000',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > svg': {
      fontSize: '11rem',
    },
  },
  tempStyles: {
    marginTop: 15,
    fontWeight: 500,
  },
}));
